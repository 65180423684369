import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import StoryArticle from "../../components/successStories/StoryArticle";
import { SUCCESS_STORIES_LIST } from "../../constants/successStoriesList";

const Beate = () => {
    const key = "Beate";
    const { name, date, image, storyShort } = SUCCESS_STORIES_LIST[key];
    const dateParts = date.split(".");
    const isoDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0] + 1).toISOString().substring(0, 10);

    const { mobile, desktop } = image;
    return (
        <Layout>
            <MetaComponents title={`${name} | miracl`} description={storyShort} />
            <GreyHeader />
            <StoryArticle
                name={name}
                date={date}
                title={"Als sie das Gespräch ..."}
                imageDesktop={desktop}
                imageMobile={mobile}
            >
                <p>
                    ... beendet hatten war sie fassungslos: Ihr Freund, mit dem sie sich gerade noch über ihre
                    gemeinsame Zukunft unterhalten hatte, hatte Beate [die Namen wurden geändert; Anm. der Redaktion]
                    soeben eröffnet, dass er sich einige Wochen zuvor beim Friseurbesuch im Nachbarort über Grobheit und
                    Langsamkeit des Lehrlings beim Waschen seines, durchaus nicht mehr vollen, Haars beschwert und dabei
                    von der Dorflehrerin Sonja, die gerade auf das Einwirken einer Tönung gewartet und deswegen Muße
                    hatte, die Misshandlungen, die er erdulden musste, zu beobachten, unvermutet Rückendeckung erhalten,
                    diese aus Dankbarkeit in das einzige Kaffeehaus im Ort eingeladen und sich nach einer Stunde
                    angeregten Gesprächs völlig überzeugt hatte, unsterblich verliebt zu sein. Dieser Eindruck sei auch
                    während der folgenden Wochen nicht gewichen, während der Michael - so der Name des sprunghaften
                    Mittfünfzigers - die Lehrerin mehrmals zum Mittagessen eingeladen hatte, rein platonisch, wie er
                    beteuerte - “wir ham noch nichts gehabt”. Dass er dies ändern wolle, ergab sich aus dem weiteren
                    Verlauf der Unterredung, die Michael mit dem Hinweis beendete, dass er Beate übermorgen, Sonntag,
                    noch einmal in ihrer Wohnung besuchen und seine Sachen abohlen würde. Für Beate brach eine Welt
                    zusammen - hatte sie mit Michael, doch nicht nur Luftschlösser gebaut, sondern auch ein Haus -
                    zumindest zur Hälfte. Wie das ganze Projekt nun finanziert werden sollte, war ihr allerdings
                    schleierhaft. Dies umso mehr als Beate zu diesem Zeitpunkt bereits kurz vor ihrem sechzigsten
                    Geburtstag stand und ihren Lebensunterhalt noch dazu als Selbstständige verdiente - zwei
                    Eigenschaften, die die Bereitschaft von Banken, Kredite zu vergeben, nicht gerade erhöhen.
                    Entsprechend aussichtslos gestaltete sich auch die Suche nach einer Finanzierung für das entstehende
                    Haus, in das Beate sich schon so sehr verliebt hatte, dass es mittlerweile auch ohne Michael
                    bewohnen wollte. Sie musste also um jeden Preis eine Finanzierung finden. Nach Wochen des Suchens,
                    in denen sie von verschiedenen Banken die immer selben Antwort erhalten hatte, immer mit dem Hinweis
                    auf ihr Alter und ihren Beschäftigungsstand und immer in ausgesuchter Höflichkeit, wollten die
                    Kreditinstitute doch keinefalls den Verdacht der Diskriminierung erwecken, nach Wochen des Suchens
                    war Beate der Verzweiflung nahe und kurz davor, ihren Traum völlig aufzugeben. Doch dann stieß sie
                    durch Zufall auf miracl. Die Aussicht, ein junges Team an ihrer Seite zu haben, gab ihr Zuversicht
                    und sie vereinbarte gleich einen Beratungstermin, den sie noch in derselben Woche erhielt. Ihr
                    Berater diskutierte mit ihr detailliert ihre finanziellen Möglichkeiten und beriet sie über
                    Strategien zur Verbesserung ihrer Bonität. Daraufhin sprach Beate noch einmal mit den zuständigen
                    Bauherrn und klärte ihn über ihre Mittel auf. Als dieser einlenkte, stellte Beate gemeinsam mit
                    ihrem Berater einen weiteren Kreditantrag. Dass dieser schließlich bewilligt wurde, gehört zu den
                    größten Erfolgen, die wir bei miracl bisher feiern durften. Denn hier wird deutlich: Auch wer nicht
                    die idealen Voraussetzungen für eine immobilienfinanzierung mitbringt, kann mit der Beratung und dem
                    Einsatz eines Teams, das für seine Aufgabe brennt, einen Kredit erhalten.
                </p>
            </StoryArticle>
            <BreadcrumbList page={key}></BreadcrumbList>
            <ArticleStructuredData
                page={key}
                heading={name}
                description={storyShort}
                datePublished={isoDate}
                dateModified={isoDate}
            />
        </Layout>
    );
};

export default Beate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
